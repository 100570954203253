(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-team/assets/javascripts/services/validate-name.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-team/assets/javascripts/services/validate-name.js');
"use strict";


const {
  jupiter
} = svs.core;
const validateTeamName = (name, callback) => {
  jupiter.get("/marketplace/1/groups/nameavailable/".concat(name), response => {
    callback(undefined, response.isNameAvailable);
  }, err => {
    callback(err.responseJSON.error);
  });
};
setGlobal('svs.components.marketplaceData.createTeam.services.validateTeamName', validateTeamName);

 })(window);