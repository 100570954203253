(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-team/assets/javascripts/create-team-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-team/assets/javascripts/create-team-actions.js');
"use strict";


const {
  dialogWrapper,
  errorCodeHandler
} = svs.components.marketplaceData;
const {
  services
} = svs.components.marketplaceData.createTeam;
const TEAM_CREATE = 'CREATE_TEAM/TEAM_CREATE';
const TEAM_CREATE_SUCCESS = 'CREATE_TEAM/TEAM_CREATE_SUCCESS';
const TEAM_CREATE_FAILURE = 'CREATE_TEAM/TEAM_CREATE_FAILURE';
const TEAM_NAME_UPDATE = 'CREATE_TEAM/TEAM_NAME_UPDATE';
const TEAM_CREATE_NAME_FORBIDDEN = 'CREATE_TEAM/TEAM_CREATE_NAME_FORBIDDEN';
const EXIT_CREATE_TEAM = 'CREATE_TEAM/EXIT_CREATE_TEAM';
const TEAM_LOOK_UP_NAME_ERROR = 'CREATE_TEAM/TEAM_LOOK_UP_NAME_ERROR';
const invalidCharRegex = /[^A-ZÅÄÖa-zåäö0-9 -]/;
const multipleSpaces = /\s\s+/g;
const edgeSpaces = /^ *| *$/g;
const teamNameChange = name => (dispatch, getState) => {
  name = name.replace(multipleSpaces, '');
  const {
    illegalNames
  } = getState().CreateTeam;
  const localError = name.length > 30 ? 'Max 30 tecken' : name.length < 2 ? 'Minst 2 tecken' : invalidCharRegex.test(name) ? 'Otillåtet tecken' : illegalNames[name] || '';
  if (!localError && !illegalNames[name]) {
    dispatch(lookUpTeamName(name));
  }
  dispatch({
    type: TEAM_NAME_UPDATE,
    validationError: localError,
    teamName: name
  });
};
const lookUpTeamName = name => dispatch => {
  services.validateTeamName(name, (error, isAvailable) => {
    if (error) {
      return dispatch({
        type: TEAM_LOOK_UP_NAME_ERROR,
        teamName: name,
        error
      });
    }
    if (!isAvailable) {
      dispatch({
        type: TEAM_CREATE_NAME_FORBIDDEN,
        teamName: name,
        error: "Lagnamnet \"".concat(name, "\" \xE4r upptaget")
      });
    }
  });
};
const createTeam = (teamName, callback) => dispatch => {
  teamName = teamName.replace(edgeSpaces, '');
  dispatch({
    type: TEAM_CREATE,
    teamName
  });
  services.createTeam(teamName, (error, data) => {
    if (error) {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch({
          type: TEAM_CREATE_FAILURE,
          teamName,
          error
        });
        dispatch(dialogWrapper.actions.showDialog({
          title: err.message,
          type: 'error'
        }));
        callback(error);
      }, {
        teamName
      });
    } else {
      dispatch({
        type: TEAM_CREATE_SUCCESS,
        team: data.newTeam,
        member: data.newMember
      });
      callback(undefined, data.newTeam);
    }
  });
};
const exitCreateTeam = () => ({
  type: EXIT_CREATE_TEAM
});
setGlobal('svs.components.marketplaceData.createTeam.actions', {
  TEAM_NAME_UPDATE,
  TEAM_CREATE,
  TEAM_CREATE_FAILURE,
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_NAME_FORBIDDEN,
  TEAM_LOOK_UP_NAME_ERROR,
  teamNameChange,
  createTeam,
  EXIT_CREATE_TEAM,
  exitCreateTeam
});

 })(window);