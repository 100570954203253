(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/create-team/assets/javascripts/services/create-team.js') >= 0) return;  svs.modules.push('/components/marketplace-data/create-team/assets/javascripts/services/create-team.js');
"use strict";


const {
  jupiter,
  session
} = svs.core;
const successCb = (response, callback) => {
  const {
    firstName,
    lastName
  } = session.data.userSession;
  const newTeam = {
    id: response.CreateGroupReply.id,
    name: String(response.CreateGroupReply.name),
    membersCount: response.CreateGroupReply.numMembers,
    token: response.CreateGroupReply.invitationToken,
    tokenExpires: new Date(response.CreateGroupReply.invitationValidUntil)
  };
  const newMember = {
    id: response.CreateGroupReply.ownerId,
    name: "".concat(firstName, " ").concat(lastName),
    role: 'CAPTAIN',
    isMe: true
  };
  callback(undefined, {
    newTeam,
    newMember
  });
};
const errorCb = (error, callback) => callback(error.responseJSON.error);
const createTeam = (name, callback) => {
  jupiter.post({
    path: '/marketplace/1/groups',
    data: {
      name,
      type: 1
    }
  }, response => successCb(response, callback), error => errorCb(error, callback));
};
setGlobal('svs.components.marketplaceData.createTeam.services.createTeam', createTeam);

 })(window);